import type { AxiosInstance } from 'axios'

import type { Api_Button } from '../types/api_button'

/**
 * Get button details
 * @param api
 * @param params
 * @param abortSignal
 * @returns
 */
export async function getSmartButton(
  api: AxiosInstance,
  params: Record<string, string>,
  abortSignal?: AbortSignal,
): Promise<Api_Button> {
  const response = await api.get(`/api/smart_buttons`, {
    params,
    signal: abortSignal,
  })
  if (!response.data || response.status !== 200) {
    throw new Error(`${response.status}`)
  }

  return response.data
}
