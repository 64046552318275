import type { AxiosInstance } from 'axios'
import axios from 'redaxios'

/**
 *
 */
export const setupRedaxiosClient = (
  apiHost: string,
  _context?: unknown,
): AxiosInstance => {
  const headers: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  const axiosInstance = axios.create({
    baseURL: apiHost,
    headers,
  }) as unknown as AxiosInstance

  return axiosInstance
}
