/**
 Problem we face with naming of interfaces from Api was that a lot of time the same type
 returns different sets of data. So the naming convention for API I came with is
 Api_{method like Create | Update, empty for get}{url}_{any subinterface of Api}

 That way you can have name completion related to types you need or are working with, ie.

 Api_ -> all API interfaces
 Api_Video -> main interface coming from `/api/videos/:id`
 Api_Video_Creator -> creator data coming as part of  Api_Video from `/api/videos/:id`

 This is very similar to the way how codegen for GraphQL with fragments and queries works,
 so will have similar learning curve later. The way how it works there

 QueryVideo -> interface for single query
 QueryVideo_feed -> feed field inside QueryName
 QueryVideo_feed_creator -> creator interface
 */

export type { ApiService } from './createApiService'
export { createApiService } from './createApiService'
export * from './types/api_adconfig'
export * from './types/api_button'
export * from './types/api_channel'
export * from './types/api_feed'
export * from './types/api_hashtag'
export * from './types/api_interactions'
export * from './types/api_livestream'
export * from './types/api_playlist'
export * from './types/api_product'
export * from './types/api_video'
