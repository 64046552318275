export interface Api_AdUnit {
  ad_insertion_method: string
  ad_sdk: 'ima' | 'admob' | 'criteo' | null
  ad_system: 'gam' | 'springserve' | 'admob' | 'criteo' | null
  ad_tag: string
  ad_format?: string
  custom_params: string
  id: string
}

export enum Api_AdPlacementType {
  PREROLL = 'preroll',
  INTERSTITIAL = 'interstitial',
  DISPLAY = 'display',
  OUTSTREAM = 'outstream',
  RM_INFEED = 'rm_infeed',
}

export interface Api_AdPlacementUnit {
  ad_tags: Api_AdUnit[]
  ad_video_interval: number
  id: string
  initial_offset: number
  placement_type: Api_AdPlacementType
  refresh_interval: number
  positions?: number[]
  autoplay_positions?: number[]
}

export interface Api_AdConfig {
  ad_target_platform: string
  context: string
  advertisable_type?: string
  advertisable_id?: string
  dedupe_direct_ads: boolean
  id: string
  placements: Api_AdPlacementUnit[]
  presentation_type: string | null
}
